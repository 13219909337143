import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  isMobileSubmited : boolean = false

  constructor() {

  }
  ngOnInit() {

  }

  isMobileNumberSubmitted(e){
    console.log(e)
    this.isMobileSubmited = e
  }


}


