import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  loginForm;
  submitted : boolean = false
 userDetail : any = {}
  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastClientService,
    private auth : AuthenticationCheckService
  ) {
    this.loginForm = formBuilder.group({
      otp: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ])],

    });
   }

  get f() { return this.loginForm.controls; }


  ngOnInit() {
    localStorage.setItem("program",'1')

    this.userDetail = JSON.parse(localStorage.getItem("user_detail"))
  }

  login(value : any){
    this.submitted = true;
    console.log(this.loginForm)
    if(this.loginForm.invalid){
      return
    }
    let payload = {
      program_id:1,
      user_id:this.userDetail.user_id,
      otp:value.otp,
      app_version:''
    }

    this.apiService.post(this.apiConfig.otp , payload).subscribe((res: any)=>{
      console.log(res)
      localStorage.setItem("user_detail", JSON.stringify(res.body.data))
      let token = `${res.body.data.access_token}`;
      this.auth.saveAuth(token);
      this.router.navigate(["hubAdmin"]);
    })


     console.log(value)
  }

  resendOtp(){
    let payload = {
      program_id:1,
      mobile_no:this.userDetail.mobile_no,
      user_id:this.userDetail.user_id,
    }
    this.apiService.post(this.apiConfig.resendOtp , payload).subscribe((res : any)=>{
      console.log(res)
      this.toast.Success(res.body.data.message)
    })
  }

}
