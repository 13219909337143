import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-xlsx-view',
  templateUrl: './xlsx-view.component.html',
  styleUrls: ['./xlsx-view.component.scss'],
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"'
  }
})
export class XlsxViewComponent implements OnInit {
   /**
   * @author kailash_dev
   * @param viewExcelData
   * Common excel view template for all the components
   * To display Excel data from Excel service
   * user able to edit and delete the record
   * < Write less & code more />
   */
  @Input() public xlsxDatas;
  xlsxCoppied:any = [];
  rowData:any;
  page = 1;
  pageSize = 50;
  tableSearch;
  wrongExcel:boolean = false;
  constructor(private activeModal: NgbActiveModal,public toast: ToastClientService,) { }

  ngOnInit() {
    this.wrongExcel = false;
    let filtered:any = this.xlsxDatas;
    filtered.data = this.xlsxDatas.data.filter(value => JSON.stringify(value) !== '{}');
    this.xlsxCoppied = JSON.parse(JSON.stringify(filtered));

    


    /** check the uploaded file is right or wrong */
    // const result1:any = this.xlsxCoppied.apiHeaders.filter((filter)=>{
    //   return this.xlsxCoppied.headers.some(excel=>{
    //      return filter == excel;
    //   });
    //  });
    //  if(result1.length != this.xlsxCoppied.apiHeaders.length){
    //   this.wrongExcel = true;
    //  }
    // 
  }
  editRow(index){
    this.xlsxCoppied.data[index].isEdit = true;
  }
  removeRow(index){
    this.removeArrayItem(index,this.xlsxCoppied.data);
  }
  updateToArray(eve,val,header,index){
    this.xlsxCoppied.data[index][header] = eve;
    // 
  }
  updateData(index) {
    this.toast.info('Data Updated!');
    this.xlsxCoppied.data[index].isEdit = false;
  }
  submit() {
      this.activeModal.close(this.xlsxCoppied.data);
  }

  close() {
      this.activeModal.close();
  }

  removeArrayItem(index, array) {
    array.splice(index, 1);
    this.toast.info('Data Removed!');
    return array;
  }
  checkURL(url) {
   let  regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(url))
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  applyFilter(filterValue: string) {
    this.xlsxCoppied.data.filter(i => i.name.toLowerCase().includes(filterValue.toLowerCase()));

  }

}
