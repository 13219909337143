import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class UploadZipService {
  /**
   * @author kailash_dev
   * @param intermediator
   * intermediator to enable event emitor between components for upload zipFile
   * < Write less & code more />
   */
  private messageSource = new BehaviorSubject({status:'Initialized'});
  currentMessage = this.messageSource.asObservable();
  constructor() { }
  changeMessage(message) {
    this.messageSource.next(message)
  }

}
